import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch, Ref } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts'
import { mapState, mapGetters } from 'vuex'
import Container from 'typedi'
import moment from 'moment'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { debounce } from 'lodash'

@Component({
  name: 'GtrRegistrationModuleDashboardView',
  components: {
    GChart
  },
  computed: {
    ...mapState('event', ['event', 'event_stats', 'eventAllContent']),
    ...mapState('module', ['activatedEventModules']),
    ...mapGetters('event', ['eventTimezone'])
  }
})
export default class GtrRegistrationModuleDashboardView extends GtrSuper {
  event?: Record<string, any>

  event_stats?: Record<string, any>

  activatedEventModules?: Record<string, any>

  eventTimezone!: string

  @Ref() regions!: HTMLElement

  @Ref() topRegions!: HTMLElement

  data () {
    return {
      payment_processor_currency: 'usd',
      event_all_content: {},
      eventStats: {},
      activation_code: null,
      geoChartOptions: {
        width: 'auto',
        colorAxis: {
          minValue: 0,
          colors: ['#F5F5F5', '#527D9F']
        }
      },
      lineChartOptions: {
        chartArea: {
          width: '85%'
        },
        legend: {
          position: 'bottom'
        },
        pointSize: 5,
        vAxis: {
          gridlines: {
            count: 0
          }
        },
        colors: ['#AEE8B1', '#FFCB9D', '#FFAABA']
      }
    }
  }

  mounted () {
    this.fetchEvent()
    this.fetchEventAllContent()
    this.fetchEventStats()
    this.getEventCode()
  }

  activated () {
    this.fetchEvent()
    this.fetchEventAllContent()
    this.fetchEventStats()
    this.getEventCode()
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEventAllContent () {
    try {
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEventStats () {
    try {
      await this.$store.dispatch('event/getEventStats', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get activeLinks () {
    const links = [
      {
        text: 'Design Event Website',
        name: 'level-two.modules.registration.design',
        icon: 'mdi-format-color-fill',
        active: true,
        permissions: ['register.design.view']
      },
      {
        text: 'Add & Edit Sessions',
        name: 'level-two.modules.track.sessions',
        icon: 'mdi-account-supervisor-circle-outline',
        active: this.activatedEventModules?.SESSION_TRACKING?.enabled,
        permissions: ['track.access.view']
      },
      {
        text: 'Add & Remove Modules',
        name: 'level-two.event.modules',
        icon: 'mdi-view-module',
        active: true,
        permissions: ['events.enable_modules.view']
      },
      {
        text: 'Manage Participants',
        name: 'level-two.event.attendees',
        icon: 'mdi-account-multiple',
        active: true,
        permissions: ['general.participants.view']
      },
      {
        text: 'Event Settings',
        name: 'level-two.modules.registration.settings',
        icon: 'mdi-cog',
        active: true,
        permissions: ['register.registration_settings.view']
      },
      {
        text: 'Webhooks',
        name: 'level-two.event.webhooks',
        icon: 'mdi-newspaper',
        active: true,
        permissions: ['general.webhooks.view']
      },
      {
        text: 'Add & Edit Integrations',
        name: 'level-two.event.syncs',
        icon: 'mdi-sync',
        active: true,
        permissions: ['general.integrations.view']
      },
      {
        text: 'Emails',
        name: 'level-two.event.emails',
        icon: 'mdi-email',
        active: true,
        permissions: ['general.emails.view']
      },
      {
        text: 'Reports',
        name: 'level-two.event.reports',
        icon: 'mdi-view-list',
        active: true,
        permissions: ['general.reports.view']
      },
      {
        text: 'Upload Images or Files',
        name: 'level-two.event.files',
        icon: 'mdi-file',
        active: true,
        permissions: ['general.files.view']
      },
      {
        text: 'Promote',
        name: 'level-two.modules.registration.promote',
        icon: 'mdi-chart-line-variant',
        active: this.activatedEventModules?.PROMOTE?.enabled,
        permissions: ['register.promote.view']
      }
    ]

    return links.filter(link => link.active)
  }

  private async getEventCode () {
    try {
      this.$data.activation_code = await this.$store.dispatch('event/loadEventCode', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
  }

  onCopy () {
    Container.get(Notification).success('Successfully copied link.')
  }

  onError () {
    Container.get(Notification).success('There was an error copying the link. Please refresh and try again.')
  }

  get regUrl (): string {
    if (this.$data.event_all_content) {
      return `${process.env.VUE_APP_REG_URL}/${this.$data.event_all_content.event.event_identifier}`
    }
    return ''
  }

  get idpEnabled (): boolean {
    return this.$data.event_all_content?.settings?.idp_login_enabled
  }

  get iframeDisplayUrl (): string {
    const title = this.$data.event_all_content ? this.$data.event_all_content.event.name : ''
    return `<iframe src="${this.regUrl}" loading="lazy" class="embed-responsive-item" style="margin: 0; padding: 0; border: none; min-height: 600px;" title="${title}" width="100%" height="100%" />`
  }

  get a2zExhibitorLoginUrl (): string {
    if (this.$data.event_all_content) {
      return `${process.env.VUE_APP_REG_URL}/${this.$data.event_all_content.event.event_identifier}/exhibitor`
    }
    return ''
  }

  get totalPayments () {
    if (this.$data.eventStats) {
      const charges = this.$data.eventStats?.paymentTypeTotals.Charge || 0
      const voids = this.$data.eventStats?.paymentTypeTotals.Void || 0
      const refunds = this.$data.eventStats?.paymentTypeTotals.Refund || 0
      return (charges + voids + refunds)
    }
  }

  get paymentProcessorCurrency () {
    return this.$data.payment_processor_currency
  }

  get geoChartData () {
    const regionStats = this.$data.eventStats.analytics_regions
    if (!regionStats) {
      return [['No Data Available']]
    }
    // since data that gets returned from BE has multiple arrays of the same countries,
    // we need to restructure and total all multiples into one array per country
    const geoChart = [['Country', 'Popularity']]
    const countryStats: any[] = []

    // find all countries and push into array with no repeats
    const countries: any[] = []
    regionStats.forEach(stat => {
      if (stat.country === null) {
        // is this manipulating too far?
        stat.country = 'United States'
      }
      if (!countries.includes(stat.country)) {
        countries.push(stat.country)
      }
    })
    // in separate array, total up popularity of each country
    const totals: any[] = []
    regionStats.forEach(stat => {
      const i = countries.indexOf(stat.country)
      if (totals[i] === undefined) {
        totals[i] = stat.total
      } else {
        totals[i] += stat.total
      }
    })

    for (let i = 0; i < countries.length; i++) {
      countryStats.push([countries[i], totals[i]])
    }

    countryStats.sort((a: any[], b: any[]) => b[1] - a[1]) // sort highest to lowest

    return geoChart.concat(countryStats)
  }

  get lineChartData () {
    const regCounts = this.$data.eventStats.registrationCountsByDate
    if (!regCounts) {
      return [['No Data Available']]
    }
    const lineChart = [['Date', 'Complete', 'Incomplete', 'Canceled']]
    for (const date in regCounts) {
      if (date === '' || date === null) {
        lineChart.push([
          date,
          regCounts[date].Complete || 0,
          regCounts[date].Incomplete || 0,
          regCounts[date].Canceled || 0
        ])
      } else {
        lineChart.push([
          moment(date).format('MM-DD-YYYY'),
          regCounts[date].Complete || 0,
          regCounts[date].Incomplete || 0,
          regCounts[date].Canceled || 0
        ])
      }
    }
    return lineChart
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  @Watch('event_stats', { immediate: true })
  onEventStatsChange (event_stats: any) {
    this.$data.eventStats = event_stats
  }

  get paymentsExportFile () {
    const totals = this.event_stats?.fee_totals
    const csvString = `Registration Fees,"${this.$options?.filters?.toCurrency(totals?.registrationTypeOptionTotal)}"\nLine Items,"${this.$options?.filters?.toCurrency(totals?.nonRegistrationTypeOptionTotal)}"\n`
    const csvFile = new File([csvString], 'payments.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  get regionalRegistrationsExportFile () {
    const regions = this.event_stats?.analytics_regions
    let csvString = 'Country,State/Province,# of Registrations\n'

    for (const region of regions) {
      csvString += region.country + ',' + region.state + ',"' + region.total.toLocaleString() + '"\n'
    }

    const csvFile = new File([csvString], 'registrations_by_region.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  get registrationStatusExportFile () {
    const statuses = this.event_stats?.statusBreakdown
    let csvString = ''

    for (const status in statuses) {
      if (status === 'Complete' || status === 'Incomplete' || status === 'Canceled') {
        csvString += status + ',"' + statuses[status].toLocaleString() + '"\n'
      }
    }

    const csvFile = new File([csvString], 'registrations_by_status.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  onGeoChartReady (chart: any, google: any) {
    const resizeMap = () => {
      const chartDataTable = google.visualization.arrayToDataTable(this.geoChartData)
      const mapContainerWidth = this.regions.offsetWidth - this.topRegions.offsetWidth
      this.$data.geoChartOptions.width = mapContainerWidth
      chart.draw(chartDataTable, this.$data.geoChartOptions)
    }

    window.addEventListener('resize', debounce(resizeMap, 100))
    setTimeout(resizeMap, 200)
  }
}
